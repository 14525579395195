(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

jQuery(function ($) {
  if ($('#itemList').length) {
    //一つ目
    var selectArray = [{
      name: '全商品',
      url: 'https://www.tsurutontan-udon.jp/SHOP/187479/list.html'
    }, {
      name: '贈答品',
      url: 'https://www.tsurutontan-udon.jp/hpgen/HPB/entries/19.html'
    }, {
      name: '詰め合わせ',
      url: 'https://www.tsurutontan-udon.jp/hpgen/HPB/entries/17.html'
    }, {
      name: 'ロカボうどん',
      url: 'https://www.tsurutontan-udon.jp/hpgen/HPB/entries/18.html'
    }, {
      name: 'おうどん',
      url: 'https://www.tsurutontan-udon.jp/SHOP/187479/187481/list.html'
    }, {
      name: 'お出汁',
      url: 'https://www.tsurutontan-udon.jp/SHOP/187479/187482/list.html'
    }, {
      name: '逸品',
      url: 'https://www.tsurutontan-udon.jp/SHOP/187479/187483/list.html'
    }, {
      name: 'お鍋',
      url: 'https://www.tsurutontan-udon.jp/hpgen/HPB/entries/20.html'
    }, {
      name: '調味料',
      url: 'https://www.tsurutontan-udon.jp/SHOP/187479/187485/list.html'
    }, {
      name: 'お菓子',
      url: 'https://www.tsurutontan-udon.jp/SHOP/187479/187486/list.html'
    }, {
      name: '雑貨',
      url: 'https://www.tsurutontan-udon.jp/SHOP/187479/187487/list.html'
    }];
    var selectOption;
    selectOption = '<option value="">絞り込み</option>';
    selectArray.forEach(function (each) {
      selectOption += '<option value="' + each.url + '">' + each.name + '</option>';
    });
    var select = '';
    select += '<select onChange="location.href=value;">';
    select += selectOption;
    select += '</select>';
    var pageTopSelectWrap = document.createElement("div");
    pageTopSelectWrap.id = "pageTopSelect";
    pageTopSelectWrap.innerHTML = select;
    $("#itemList").find("h1").after(pageTopSelectWrap); //二つ目

    var selectArrayPrice = [{
      name: '~1,999円',
      url: 'https://www.tsurutontan-udon.jp/SHOP/194280/list.html'
    }, {
      name: '2,000円~2,999円',
      url: 'https://www.tsurutontan-udon.jp/SHOP/194281/list.html'
    }, {
      name: '3,000円~4,999円',
      url: 'https://www.tsurutontan-udon.jp/SHOP/194282/list.html'
    }, {
      name: '5,000円~',
      url: 'https://www.tsurutontan-udon.jp/SHOP/194283/list.html'
    }];
    var selectOptionPrice;
    selectOptionPrice = '<option value="">価格から絞り込み</option>';
    selectArrayPrice.forEach(function (each) {
      selectOptionPrice += '<option value="' + each.url + '">' + each.name + '</option>';
    });
    var selectPrice = '';
    selectPrice += '<select onChange="location.href=value;">';
    selectPrice += selectOptionPrice;
    selectPrice += '</select>';
    var pageTopSelectWrapPrice = document.createElement("div");
    pageTopSelectWrapPrice.id = "pageTopSelectPrice";
    pageTopSelectWrapPrice.innerHTML = selectPrice;
    $("#itemList").find("#pageTopSelect").after(pageTopSelectWrapPrice);
  }

  if ($("#itemList").length) {
    var getBread = $("#bread-crumb-listTop").find('li');
    var last = getBread[getBread.length - 1].textContent;

    if (last === "商品") {
      $("#itemList").find('h1').text('全商品');
    } else {
      $("#itemList").find('h1').text(last + '一覧');
    }
  }
});

},{}]},{},[1]);
